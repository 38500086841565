export function ArrowDown() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.90959 14.9914L9.95991 14.9983L10.0406 14.9982L10.1108 14.9871L10.1389 14.978C10.2517 14.9455 10.3475 14.8715 10.4114 14.7733L13.8548 10.941C14.0484 10.7257 14.0484 10.3767 13.8548 10.1614C13.6828 9.9701 13.4158 9.94884 13.2226 10.0977L13.154 10.1614L10.495 13.118L10.4956 5.51268C10.4956 5.22954 10.2737 5 10 5C9.7567 5 9.55435 5.18136 9.51239 5.42053L9.5044 5.51268L9.504 13.117L6.84604 10.1614C6.674 9.9701 6.40695 9.94884 6.2138 10.0977L6.14516 10.1614C5.97312 10.3528 5.954 10.6498 6.08781 10.8646L6.14516 10.941L9.58863 14.7733C9.65251 14.8715 9.74834 14.9455 9.86069 14.9795L9.90959 14.9914Z"
        fill="#606B85"
      />
    </svg>
  );
}
