export function ChevronRight() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70711 6.29289C9.31658 5.90237 8.68342 5.90237 8.29289 6.29289C7.93241 6.65338 7.90468 7.22061 8.2097 7.6129L8.29289 7.70711L10.585 10L8.29289 12.2929C7.93241 12.6534 7.90468 13.2206 8.2097 13.6129L8.29289 13.7071C8.65338 14.0676 9.22061 14.0953 9.6129 13.7903L9.70711 13.7071L12.7071 10.7071C13.0676 10.3466 13.0953 9.77939 12.7903 9.3871L12.7071 9.29289L9.70711 6.29289Z"
        fill="#606B85"
      />
    </svg>
  );
}
